import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Avatar extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    imgClassName: PropTypes.string,
    onClick: PropTypes.func,
    src: PropTypes.string,
    alt: PropTypes.string,
    defaultAvatar: PropTypes.string,
    defaultOrganizationAvatar: PropTypes.string,
    defaultType: PropTypes.string,
    size: PropTypes.oneOf(['xxsm', 'xsm', 'sm', 'md', 'lg', 'xlg', '2xl']),
    square: PropTypes.bool,
    isManagedConsultant: PropTypes.bool,
    isOrganization: PropTypes.bool,
    bottomLeftAction: PropTypes.any,
    bottomRightAction: PropTypes.any,
    hoverable: PropTypes.bool,
  }

  static defaultProps = {
    onClick: () => {},
    alt: 'avatar',
    defaultAvatar: '/blankuseravatar.png',
    defaultOrganizationAvatar: '/blankorganizationavatar.png',
    defaultType: 'image/png',
    isOrganization: false,
    hoverable: false,
  }

  constructor(props) {
    super(props);
    const defaultAvatar = props.isOrganization ? props.defaultOrganizationAvatar : props.defaultAvatar;
    this.state = {
      src: props.isManagedConsultant ? '/managedconsultantavatar.png' : (props.src || defaultAvatar),
      defaultAvatar,
    }
  }

  get classNames() {
    const { className, size, square, hoverable } = this.props;
    return classNames('avatar', {
      [className]: className,
      'is-2xl': size === '2xl',
      'is-xlarge': size === 'xlg',
      'is-large': size === 'lg',
      'is-medium': size === 'md',
      'is-small': size === 'sm',
      'is-xsmall': size === 'xsm',
      'is-xxsmall': size === 'xxsm',
      'is-square': square,
      'is-hoverable': hoverable,
    });
  }

  get imgClassNames() {
    const { imgClassName, size, square } = this.props;

    if (!imgClassName && !size) {
      return '';
    }

    return classNames('avatar', {
      [imgClassName]: imgClassName,
      'is-xlarge': size === 'xlg',
      'is-large': size === 'lg',
      'is-medium': size === 'md',
      'is-small': size === 'sm',
      'is-xsmall': size === 'xsm',
      'is-xxsmall': size === 'xxsm',
      'is-square': square,
    });
  }

  handleError = () => {
    const { defaultAvatar } = this.props;
    this.setState({ src: defaultAvatar });
  }

  render() {
    const { onClick, alt, defaultType, bottomRightAction, bottomLeftAction } = this.props;
    const { src } = this.state;

    return (
      <div className={this.classNames}>
        <img src={src}
          onClick={onClick}
          type={defaultType}
          alt={alt}
          className={this.imgClassNames}
          onError={this.handleError}
        />
        <div className='hover-overlay' />
        {
          bottomLeftAction &&
          <div className='bottom-left-action'>
            {bottomLeftAction}
          </div>
        }
        {
          bottomRightAction &&
          <div className='bottom-right-action'>
            {bottomRightAction}
          </div>
        }
      </div>
    );
  }
}