import React from 'react';
import classNames from 'classnames';

// Mixins
import isResizable from '../../../../mixins/resizable';

// Base
import Card from '../../../base/card';

class _TrustedCompanies extends React.Component {
  get companies() {
    return [
      { src: 'https://oomple.com/hubfs/assets/oomple/assets/homepage/logos/salesforce.png', alt: 'Sales Force logo' },
      { src: 'https://oomple.com/hubfs/assets/oomple/assets/homepage/logos/oracle.png', alt: 'Oracle logo' },
      { src: 'https://oomple.com/hubfs/assets/oomple/assets/homepage/logos/volvo.png', alt: 'Volvo logo' },
      { src: 'https://oomple.com/hubfs/assets/oomple/assets/homepage/logos/edc.png', alt: 'Edc logo' },
      { src: 'https://oomple.com/hubfs/assets/oomple/assets/homepage/logos/boc.png', alt: 'Boc logo' },
      { src: 'https://oomple.com/hubfs/assets/oomple/assets/homepage/logos/vmware.png', alt: 'Vmware logo' },
      { src: 'https://oomple.com/hubfs/assets/oomple/assets/homepage/logos/bell.png', alt: 'Bell logo' },
      { src: 'https://oomple.com/hubfs/assets/oomple/assets/homepage/logos/hexo.png', alt: 'Hexo logo' },
    ]
  }

  render() {
    const { isDesktop } = this.props;
    return (
      <Card
        id='trusted-companies'
        color='light'
        className={classNames('trusted-companies width-auto', {
          'flex vertical-align min-height-55 padding-top-0 padding-bottom-0': isDesktop,
          'min-height-70 none': !isDesktop,
        })}
      >
        {
          isDesktop &&
          <div className='width-110 margin-right-10'>Trusted by 2k+<br />companies like:</div>
        }
        {
          !isDesktop &&
          <div className='margin-bottom-10'>Trusted by 2k+ companies like:</div>
        }

        <div>
          {
            this.companies.map((company, index) => (
              <img
                src={company.src}
                alt={company.alt}
                key={index}
                height='50'
              />
            ))
          }
        </div>
      </Card>
    )
  }
}
export default isResizable(_TrustedCompanies);