import Model from './model';
import Persona from './persona';
import Query from './query';

export default class User extends Model {
  static NAME = 'user';
  static URL = '/api/v2/users';

  static CONSULTANT = 'consultant';
  static COMPANY = 'company';
  static AGENT = 'agent';

  static userTypeData = {
    consultant: {
      name: 'Professional',
      description: 'Find Opportunities',
      img: 'https://www.oomple.com/hubfs/assets/oomple/assets/app/signup-professional.png',
    },
    agent: {
      name: 'Employer',
      description: 'Hire Talent',
      img: 'https://www.oomple.com/hubfs/assets/oomple/assets/app/signup-employer.png',
    }
  };

  canNudge = () => {
    if (!this.nudged_at) return true;
    return !(this.nudged_by || []).includes(gon.currentUser.id.toString());
  };

  canAvailabilityNudge = () => {
    if (!this.availability_nudged_at) return true;
    return !(this.availability_nudged_by || []).includes(gon.currentUser.id.toString());
  };

  managedConsultantDiff = () => {
    const query = new Query(this);
    const url = `/api/internal/users/${this.id}/managed_consultant_diff`;
    return query.url(url).get();
  }

  managedConsultantMerge = () => {
    const query = new Query(this);
    const url = `/api/internal/users/${this.id}/managed_consultant_merge`;
    return query.url(url).post();
  }
}
