import Model from './model';

export default class Persona extends Model {
  static NAME = 'persona';
  static URL = '/api/v2/personas';

  static displayName = (name) => {
    const newName = name ? name.toLowerCase(name) : '';
    return newName === 'default' ? '' : name;
  }

  certificationsAttributes = (categories, originalCategories, bypassNotExisting) => {
      let toRemove = [];

      // Find any certifications that exist on the persona, and check if they're in this list of categories
      // If not, then add them to the list of certification_attributes that should be removed
      if (!bypassNotExisting) {
        const isNotExisting = (this.certification_selections_attributes || []).filter((csa) => !categories.some((cat) => csa.certification_id === cat.id));
        if (isNotExisting.length > 0) {
          const destroy = isNotExisting.map((cert) => ({ ...cert, _destroy: true }));
          toRemove.push(destroy);
        }
      }

      // Build list of categories that have changed
      const isChanged = categories.filter((cat) => {
        // Find matching original entry based on category name and type
        const orig = originalCategories.find((orig) => orig.category_name == cat.category_name && orig.categorizable_type == cat.categorizable_type);
        // Has been changed if either certified or name values have changed
        return orig.certified !== cat.certified || orig.name !== cat.name;
      });

      //Grab the remaining unchanged categories and convert to standard objects
      const unChanged = categories.filter((cat) => !isChanged.includes(cat)).map((cat) => ({...cat}));

      const newCertifications = isChanged.map((changed) => {
        const original = originalCategories.find((orig) => orig.category_name === changed.category_name && orig.categorizable_type == changed.categorizable_type);
        // If it is currently certified, then the name has changed, and we're all good
        if (changed.certified) {
          return { ...changed };
        } else if (original.certified) {
          // If the original was certified, and changed was not certified, then we need to delete it
          return { ...changed, _destroy: true };
        }
      });

      // Combine all elements
      return [ ...toRemove, ...unChanged,...newCertifications];
  }
}
