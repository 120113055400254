import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Avatar from './avatar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class Card extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    titleColor: PropTypes.string,
    stacked: PropTypes.bool,
    square: PropTypes.bool,
    expandable: PropTypes.bool,
    iconSize: PropTypes.oneOf(['xsm', 'sm', 'md', 'lg', 'xlg']),
    avatarSize: PropTypes.oneOf(['xsm', 'sm', 'md', 'lg', 'xlg']),
    isOrganization: PropTypes.bool,
    color: PropTypes.oneOf(['dark', 'light', 'outline', 'success', 'danger']),
    avatar: PropTypes.string,
    icon: PropTypes.object,
    iconClass: PropTypes.string,
    action: PropTypes.object,
    actionClass: PropTypes.string,
    onClick: PropTypes.func,
    onTitleClick: PropTypes.func,
    disabled: PropTypes.bool,
    isSelected: PropTypes.bool,
    selectedColor: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
    displayEdge: PropTypes.bool,
    edgeColor: PropTypes.string,
    hoverable: PropTypes.bool,
    noPadding: PropTypes.bool,
    read: PropTypes.bool,
    listItem: PropTypes.bool,
    useAvatar: PropTypes.bool,
    droppable: PropTypes.bool,
    border: PropTypes.oneOf(['dashed', 'none', 'thin']),
    selectedContent: PropTypes.object,
    selectedContentClass: PropTypes.string,
    onSelectedContentClick: PropTypes.func,
    topLeftAction: PropTypes.object,
    topLeftActionClass: PropTypes.string,
  };

  static defaultProps = {
    id: '',
    title: '',
    iconSize: 'md',
    avatarSize: 'md',
    isOrganization: false,
    stacked: false,
    expandable: false,
    color: 'light',
    onClick: () => { },
    onTitleClick: () => { },
    disabled: false,
    isSelected: false,
    displayEdge: false,
    edgeColor: '',
    hoverable: false,
    noPadding: false,
    read: false,
    listItem: false,
    useAvatar: false,
    droppable: false,
    onSelectedContentClick: () => { },
  };

  constructor(props) {
    super(props);
    this.container = React.createRef();
    this.state = {
      height: 0,
      expanded: false,
    };
  }

  componentDidMount() {
    if (this.props.square) {
      window.addEventListener('resize', this.calculateHeight);
      this.calculateHeight();
    }
  }

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    const { square } = this.props;
    // If the square prop changes, recalculate the height
    if (prevProps.square != square && square) {
      this.calculateHeight();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateHeight);
  }

  get isDark() {
    return this.props.color === 'dark';
  }

  get isLight() {
    return this.props.color === 'light';
  }

  get isOutline() {
    return this.props.color === 'outline';
  }

  get isSuccess() {
    return this.props.color === 'success';
  }

  get isDanger() {
    return this.props.color === 'danger';
  }

  get classNames() {
    const {
      className, stacked, expandable,
      icon, avatar, disabled, iconSize,
      avatarSize, isSelected, selectedColor,
      displayEdge, hoverable, noPadding, read,
      title, listItem, useAvatar, droppable,
      border,
    } = this.props;

    const avatarSizeClass = `avatar-${avatarSize}`;
    const iconSizeClass = `icon-${iconSize}`;

    return classNames('card', {
      'is-stacked': stacked,
      'is-expandable': expandable,
      'is-light': this.isLight,
      'is-dark': this.isDark,
      'is-success': this.isSuccess,
      'is-danger': this.isDanger,
      'is-outline': this.isOutline,
      'is-list-item': listItem,
      'has-avatar': !!avatar || !!icon || useAvatar,
      'is-disabled': disabled,
      'is-selected': isSelected,
      [selectedColor]: selectedColor,
      'is-read': read,
      'has-edge': displayEdge,
      'has-title': title,
      'hoverable': hoverable,
      'padding-0': noPadding,
      'is-droppable': droppable,
      [border]: border,
      [iconSizeClass]: !!icon,
      [avatarSizeClass]: !!avatar,
      [className]: className,
    });
  }

  get selectedContentClassNames() {
    const { isSelected, selectedContentClass } = this.props;
    return classNames('selected-content absolute right-0 top-0 bottom-0 gray-600-border solid-border-left-1 padding-10', {
      'is-light': this.isLight,
      'is-dark': this.isDark,
      'is-success': this.isSuccess,
      'is-danger': this.isDanger,
      'is-outline': this.isOutline,
      [selectedContentClass]: selectedContentClass,
      'visible': isSelected,
    })
  }

  handleClick = (e) => {
    if (
      !$(e.target).closest('a')[0] &&
      !$(e.target).closest('button')[0] &&
      !$(e.target).closest('.input-field')[0] &&
      !['BUTTON', 'A'].includes(e.target.tagName)
    ) {
      this.props.onClick(e);
    }
  }

  calculateHeight = () => {
    this.setState({ height: this.container.current.getBoundingClientRect().width });
  };

  toggleExpand = (e) => {
    e.preventDefault();
    this.setState({ expanded: !this.state.expanded });
    const containerContent = document.querySelector('.fixed-width-layout > .content');
    const containerSidebar = document.querySelector('.fixed-width-layout > .sidebar');
    containerContent.classList.toggle('is-shrunk');
    containerSidebar.classList.toggle('is-expanded')
  }

  // Want to hide actions when the selected card shows up
  // (if it's set)
  get showAction() {
    const { isSelected, selectedContent } = this.props;
    if (isSelected && selectedContent) {
      return false;
    }
    return true;
  }

  render() {
    const {
      square, expandable, action, children, id, icon, avatar, title, displayEdge, edgeColor,
      onTitleClick, avatarSize, titleColor, isOrganization, useAvatar, iconClass, selectedContent,
      actionClass, onSelectedContentClick, topLeftAction, topLeftActionClass,
    } = this.props;

    const { height, expanded } = this.state;

    return (
      <div
        className={this.classNames}
        ref={this.container}
        style={square ? { height } : {}}
        id={id}
        onClick={this.handleClick}
      >
        {
          topLeftAction &&
          <div className={classNames('absolute left-10 top-8 z-index-5', { [topLeftActionClass]: topLeftActionClass })}>
            {topLeftAction}
          </div>
        }
        {displayEdge && <div className={`edge ${edgeColor}`} />}
        {title && <h3 className={`card-title ${titleColor}`} onClick={onTitleClick}>{title}</h3>}
        {
          (avatar || useAvatar) &&
          <Avatar
            isOrganization={isOrganization}
            className='margin-0'
            src={avatar}
            size={avatarSize}
          />
        }
        {
          icon &&
          <FontAwesomeIcon
            className={classNames('icon', { [iconClass]: iconClass })}
            icon={icon}
          />
        }
        {
          this.showAction &&
          <div className={classNames('card-action', { [actionClass]: actionClass })}>{action}</div>
        }
        {children}
        {
          expandable &&
          <a href='#' onClick={this.toggleExpand} className='card-expand-button'>
            <i className={classNames('fa', { 'fa-angle-left': expanded, 'fa-angle-right': !expanded})} />
          </a>
        }
        {
          this.isDark &&
          <div className='hover-overlay' />
        }
        {
          selectedContent &&
          <div
            className={this.selectedContentClassNames}
            onClick={onSelectedContentClick}
          >
            {selectedContent}
          </div>
        }
      </div>
    )
  }
}
