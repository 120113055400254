export default class Window {
  static flash = {
    error: (error, message = 'An error has occurred. Please reload and try again.') => {
      console.log('error', error);
      window.flash('alert', message);
    },
    success: (message) => {
      window.flash('notice', message || 'Success!');
    }
  }

  static open = (url, name = '_blank') => {
    window.open(url, name);
  }

  static redirect = (url, windowOverride) => {
    const win = windowOverride || window;
    win.location.href = url;
  }

  static updateParams = (params) => {
    const p = $.param(params);
    const path = `${window.location.pathname}${p ? `?${p}` : ''}`;
    window.history.replaceState({ url: path }, document.title, path);
  }

  static pushParams = (params) => {
    const p = $.param(params);
    const path = `${window.location.pathname}${p ? `?${p}` : ''}`;
    window.history.pushState({ url: path }, document.title, path);
  }

  static currentDomain = () => {
    return window.location.origin;
  }
}