import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import update from 'immutability-helper';
import moment from 'moment';
import numeral from 'numeral';
import pluralize from 'pluralize';

// Will recursively search through the children for react objects to add a method to
export function addMethodToChildren(children, propName, func, filterFunc) {
  if (typeof children === 'string' || children.type == FontAwesomeIcon) return children;
  filterFunc = filterFunc || function () { return true };

  return React.Children.map(children, (c) => {
    if (!c) return c;
    if (typeof c === 'string') return c;
    if (!filterFunc(c)) return false;

    // Only add propname to react components
    const newProps = (c.type instanceof Function) ? { [propName]: func } : {};

    // Add method to all chilren if they exist
    const newChildren = (c.props && c.props.children) ?
      addMethodToChildren(c.props.children, propName, func) :
      undefined;
    return React.cloneElement(c, newProps, newChildren);
  });
};

export const uuid = (function () {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return function () {
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
      s4() + '-' + s4() + s4() + s4();
  };
})();

export const objectify = (collection, label = 'id') => {
  return (
    collection.reduce((result, element) => {
      result[element[label]] = element;
      return result;
    }, {})
  );
};

export const sameObjects = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export const removeEmptyKeys = function(obj, isTruthy) {
  var isTruthy = isTruthy || false;
  var newObj = {};

  let key;
  for(key in obj) {
    var value = obj[key]

    if(!(value === undefined || value === null || value.length === 0 || (isTruthy && !value))) {
      newObj[key] = value;
    }
  }

  return newObj;
}

export const deparam = function( params, coerce ) {
  var decode = decodeURIComponent;

  var obj = {},
    coerce_types = { 'true': !0, 'false': !1, 'null': null };

  $.each( params.replace( /\+/g, ' ' ).split( '&' ), function(j,v){
    var param = v.split( '=' ),
      key = decode( param[0] ),
      val,
      cur = obj,
      i = 0,

      keys = key.split( '][' ),
      keys_last = keys.length - 1;

    if ( /\[/.test( keys[0] ) && /\]$/.test( keys[ keys_last ] ) ) {
      keys[ keys_last ] = keys[ keys_last ].replace( /\]$/, '' );
      keys = keys.shift().split('[').concat( keys );

      keys_last = keys.length - 1;
    } else {
      keys_last = 0;
    }

    if ( param.length === 2 ) {
      val = decode( param[1] );

      if ( coerce ) {
        val = val && !isNaN(val)            ? +val
          : val === 'undefined'             ? undefined
          : coerce_types[val] !== undefined ? coerce_types[val]
          : val;
      }

      if ( keys_last ) {
        for ( ; i <= keys_last; i++ ) {
          key = keys[i] === '' ? cur.length : keys[i];
          cur = cur[key] = i < keys_last
            ? cur[key] || ( keys[i+1] && isNaN( keys[i+1] ) ? {} : [] )
            : val;
        }

      } else {

        if ($.isArray( obj[key] ) ) {
          obj[key].push( val );

        } else if ( obj[key] !== undefined ) {
          obj[key] = [ obj[key], val ];

        } else {
          obj[key] = val;
        }
      }

    } else if ( key ) {
      obj[key] = coerce
        ? undefined
        : '';
    }
  });

  return obj;
};

export const isEmptyObject = function(obj) {
  return $.isEmptyObject(obj);
}

export const removeEmptyElementsFromArray = function(array) {
  return array.filter((el) => !(el === undefined || el === null || el === ''));
}

export const getDurationInMonths = function(startDate, endDate) {
  const start = moment(startDate);
  const end = moment(endDate);
  return moment.duration(end.diff(start)).asMonths();
}

export const getFormattedDurationInMonths = function(startDate, endDate) {
  const months = getDurationInMonths(startDate, endDate);
  const formattedMonths = numeral(months).format('0.00');
  return `${formattedMonths} ${pluralize('month', formattedMonths)}`;
}